// Colors
$white: #fff !default;
$black: #000 !default;
%clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}

@mixin inlineblock($valign: top) {
  display: inline-block;
  vertical-align: $valign;
}

@mixin centerer($horizontal: true, $vertical: true) {
  position: absolute;

  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if ($horizontal) {
    left: 50%;
    transform: translate(-50%, 0);
  } @else if ($vertical) {
    top: 50%;
    transform: translate(0, -50%);
  }
}

@mixin absolute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin ghostVerticalAlign() {
  &:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 0.1px;
  }
}

@mixin ghostHorizontalAlign() {
  text-align: justify;
  text-justify: distribute;

  &:after {
    content: "";
    display: inline-block;
    width: 100%;
  }
}

@mixin input-placeholder {
  &.placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

@function rem($size) {
  @return $size / 16px+0rem;
}

@mixin fontSize($size) {
  font-size: $size;
  font-size: rem($size);
}

@mixin desktop {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

@mixin laptop {
  @media screen and (max-width: 992px) {
    @content;
  }
}

@mixin tablets {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin phones {
  @media screen and (max-width: 480px) {
    @content;
  }
}

@mixin imgGlitch($name, $intensity, $width, $height, $top, $left) {
  $steps: $intensity;
  @at-root {
    @for $i from 1 through 2 {
      @keyframes #{$name}-anim-#{$i} {
        @for $i from 0 through $steps {
          #{percentage($i*(1/$steps))} {
            clip: rect(
              random($height) + px,
              $width + px,
              random($height) + px,
              0
            );

            -ms-transform: scale(1.2) skewX(random(30) - 30 + deg);
            -webkit-transform: scale(1.2) skewX(random(30) - 30 + deg);
            transform: scale(1.2) skewX(random(10) - 10 + deg);

            -webkit-filter: saturate(9);
            filter: saturate(9);
          }
        }
      }
    }
  }

  > img {
    position: absolute;
    top: $top + px;
    left: $left + px;
  }
  > img:nth-child(2),
  > img:nth-child(3) {
    clip: rect(0, 0, 0, 0);
  }
  > img:nth-child(2) {
    left: ($left + 2) + px;
    animation: #{$name}-anim-1 2s infinite linear alternate-reverse;
  }
  > img:nth-child(3) {
    left: ($left - 2) + px;
    animation: #{$name}-anim-2 3s infinite linear alternate-reverse;
  }
}


















































































































































































.comments {
    position: relative;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
}

.comments-label {
    width: calc(50% - 5px);
    font-size: 14px;
    color: #808080;
    margin-top: 10px;
    margin-right: 10px;
    p {
        margin: 0;
        padding-bottom: 2px;
    }
    p:nth-child(1) {
        padding-bottom: 5px;
    }
}

.comments-content {
    margin-top: 10px;
    width: calc(50% - 5px);
    font-size: 14px;
    color: #333;
}

.comments-buttons {
    display: grid;
    grid-template-columns: 200px 100px;

    button {
        border: none;
        background: none;
        margin-bottom: 5px;
        margin-right: 2em;
        padding: 0;
        text-align: left;
        cursor: pointer;
        &:hover {
            color: #6f61e9;
        }
    }
}

.comments-last-comment {
    min-height: 83px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 22px;
    color: #333;
    background: #f6f6f8;
    border: 1px solid transparent;
    margin-bottom: 5px;
    padding: 10px 12px;
    word-wrap: break-word;
}

.comments-input-wrapper {
    width: 100%;
    padding: 45px;
    text-align: center;
}

.comments-input-title,
.comments-history-title {
    font-size: 20px;
    font-weight: 700;
    color: #333;
    padding-bottom: 15px;
}
.comments-history-title {
    padding-bottom: 25px;
}

.comments-input-buttons {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;

    &.single {
        justify-content: center;
    }
}

.comments-history-wrapper {
    padding: 45px 5px;
    height: 100%;
    text-align: center;
}

.comments-history-items {
    height: calc(100% - 118px);
    overflow-y: scroll;
    padding: 0px 40px 25px 40px;
    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-thumb {
        background: #6f61e9;
        border-radius: 3px;
    }
}

.comments-history-empty {
    height: 437px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.comments-history-buttons {
    padding-top: 25px;
}

.comments-item {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    &-label {
        width: calc(50% - 5px);
        margin-top: 10px;
        margin-right: 10px;
        text-align: left;
        font-size: 14px;
        color: #808080;
        p {
            margin: 1px 0;
        }
    }
    &-text {
        width: calc(50% - 5px);
        background: #fff;
        border: 1px solid #e0dfee;
        border-radius: 4px;
        text-align: left;
        font-size: 14px;
        line-height: 22px;
        color: #333;
        padding: 9px 12px;
        word-wrap: break-word;
    }
}

.loader {
    height: 437px;
    display: flex;
    align-items: center;
    justify-content: center;
}
